import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { faAngleRight, faHome } from '@fortawesome/free-solid-svg-icons';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { KeyEnum } from './resources/enums/keyEnum.enum';
import { TranslateService } from '@ngx-translate/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { PopupCookiesComponent } from './components/popup-cookies/popup-cookies.component';
import { PoliticaCookiesModalComponent } from './components/footer/politica-cookies-modal/politica-cookies-modal.component';
import { OutputPPCookies, PPCookiesEnum } from './models/componentes/popup-cookies.model';
import { CookieEnum } from './resources/enums/cookie.enum';

declare var $: any;
declare var gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  //#region atributos
  public cc = window as any;
  public flechaDcha = faAngleRight;
  public home = faHome;

  // Cookies analytics
  public subscricionAnalitys: Subscription;
  public analyticsActiva: boolean;

  public title: string;
  //#endregion

  //#region contructor
  constructor(
    private router: Router,
    private translate: TranslateService,
    private cookieService: CookieService,
    private simpleModalService: SimpleModalService) {
    this.title = 'GES005';

    this.iniciarAnalytics();
  }
  //#endregion

  //#region Metodos Publicos (accesible html)
  public ngOnInit(): void {
    this.IniciarJSDiseño();
    this.IniciarCookie();
  }

  public subir(): void {
    window.scroll({
      top: 100,
      left: 100,
      behavior: 'smooth'
    });
  }

  //#endregion

  //#region Metodos Privados

  private iniciarAnalytics(): void {
    // Por defecto esta activas las analyticas de google a no ser que en las cookies hayamos guardado que no las quiere
    this.analyticsActiva = true;
    const cookieAnalytics = this.cookieService.get(KeyEnum.analytics);
    // Comprobamos que la cookie tenga valor y este sea un boleano
    if (cookieAnalytics !== '' && Boolean(cookieAnalytics)) {
      this.analyticsActiva = cookieAnalytics.toLowerCase() === 'true';
    }

    if (this.analyticsActiva) {
      // filtramos los eventos para las rutas y nos quedamos
      // solos con los que sean parte de la navegación final
      const eventoNavFinal = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      );
      // Nos subscribimos al evento para que cada vez que cambie se
      // lo enviemos a google analitics
      this.subscricionAnalitys = eventoNavFinal.subscribe((event: NavigationEnd) => {
        gtag('config', 'UA-23325945-11', {
          page_path: event.urlAfterRedirects
        });
      });
    }
  }

  private IniciarCookie(): void {
    // Libreria añadida en el index para mostrar el mensaje de cookies.
    this.cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: 'aliceblue',
          text: '#000'
        },
        button: {
          background: '#d86018',
          text: '#fff'
        }
      },
      theme: 'classic',
      layout: 'my-custom-layout',
      layouts: {
        'my-custom-layout': '{{buttons}}'
      },
      elements: {
        buttons:
          `
        <div id="cookieconsent:desc" class="cc-compliance cookies-wrapper">
          <div class="cookies-layout">
            {{message}}
          </div>
          <div class="cookies-buttons">
            <button id='btnCCConfig' class='btn btn-warning'>${this.translate.instant('BannerCookies.configurar')}</button>
            <button id='btnCCAceptar' class='btn btn-primary'>${this.translate.instant('BannerCookies.aceptar')}</button>
          </div>
        </div>
        `,
      },
      content: {
        message:
          `
        <div class="cookies-title">${this.translate.instant('BannerCookies.titulo')}</div>
        <p>${this.translate.instant('BannerCookies.mensaje1')}</p>
        <p>${this.translate.instant('BannerCookies.mensaje2')} <a id="lnkPolitica" role='button' tabindex='0' class='cc-link' rel='noopener noreferrer nofollow'>${this.translate.instant('BannerCookies.mensajeEnlace')}</a></p>
        <p>${this.translate.instant('BannerCookies.mensaje3')}</p>
        `
      }
    });
    this.configurarBotonesCookies();
  }

  private IniciarJSDiseño(): void {
    // Img crop IE
    if ('objectFit' in document.documentElement.style === false) {
      document.addEventListener('DOMContentLoaded', function (): void {
        Array.prototype.forEach.call(document.querySelectorAll('img[data-object-fit]'), function (image: any): void {
          (image.runtimeStyle || image.style).background = 'url("' + image.src + '") no-repeat 50%/' + (image.currentStyle ? image.currentStyle['object-fit'] : image.getAttribute('data-object-fit'));
          image.src = 'data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'' + image.width + '\' height=\'' + image.height + '\'%3E%3C/svg%3E';
        });
      });
    }

  }

  private configurarBotonesCookies(): void {
    $('#btnCCConfig').on('click', () => {
      this.customomizeCookies();
    });

    $('#btnCCAceptar').on('click', () => {
      this.dismiss();
    });

    $('#lnkPolitica').on('click', () => {
      this.openModalCookies();
    });
  }

  private dismiss(): void {
    // Establecemos a mano la cookie para que no se vuelva a mostrar al volver a entrar en la web
    this.cookieService.set('cookieconsent_status', 'allow');
    $('.cc-window').hide(); // Ocultado el div de cookies
  }

  private async customomizeCookies(): Promise<void> {
    const modal = this.simpleModalService.addModal(PopupCookiesComponent).toPromise();

    await modal.then(async (output: OutputPPCookies) => {
      // Si es el botón guardar configuración modificamos la cookie
      // Si es el de aceptar llamamos al dismiss
      switch (output.btnSelec) {
        case PPCookiesEnum.btnAceptar:
          this.dismiss();
          break;
        case PPCookiesEnum.btnGuardar:
          this.cookieService.set(CookieEnum.Analytics, String(output.cookieAnalyticSel));

          if (!output.cookieAnalyticSel) {
            this.subscricionAnalitys.unsubscribe();
          }
          this.dismiss();
          break;
      }

    });
  }  

  private openModalCookies(): void {
    this.simpleModalService.addModal(PoliticaCookiesModalComponent, {});
  }

  //#endregion
}
