import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppRoutes } from 'src/app/app-routes';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  showError: boolean = false;
  messageError: string = "";

  constructor(
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {

    if (environment.production) {
      this.showError = false;
    }
    else {
      this.route.params.subscribe(
        (params: Params) => {
          this.messageError = params.message;
          this.showError = true;
        });
    }
  }

  public Inicio(): void {
    this.router.navigate([AppRoutes.INICIO], { replaceUrl: true });
  }

}
