import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sesion-expirada',
  templateUrl: './sesion-expirada.component.html',
  styleUrls: ['./sesion-expirada.component.scss']
})
export class SesionExpiradaComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  public inicio(): void {
    this.router.navigate(['/'], { replaceUrl: true });
  }

}
