import { NgModule } from '@angular/core';

import { ClickCursorDirective } from './click-cursor.directive';

@NgModule({
  declarations: [
    // Directives
    ClickCursorDirective
  ],
  imports: [
  ],
  exports: [
    ClickCursorDirective
  ]
})
export class ClickCursorModule { }
