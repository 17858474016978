import { AppComponent } from './app.component';

import es from '@angular/common/locales/es';
import { CommonModule, LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Servicios
import { CacheService } from './services/cache.service';
import { CookieService } from 'ngx-cookie-service';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';

// Módulos
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgModule, LOCALE_ID, Injector, APP_INITIALIZER } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { defaultSimpleModalOptions, SimpleModalModule } from 'ngx-simple-modal';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClickCursorModule } from './directives/click-cursor/click-cursor.module';
import { HeaderModule } from './components/header/header.module';
import { FooterModule } from './components/footer/footer.module';

// Componentes
import { EnConstruccionComponent } from './pages/en-construccion/en-construccion.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PopupCookiesComponent } from './components/popup-cookies/popup-cookies.component';
import { SesionExpiradaComponent } from './pages/sesion-expirada/sesion-expirada.component';
import { ErrorComponent } from './pages/error/error.component';

// Pipes
import { BooleanToSiNoPipe } from 'src/app/pipes/booleanToSiNo.pipe';

// Loading
import { NgxUiLoaderHttpModule, NgxUiLoaderModule, NgxUiLoaderRouterModule } from 'ngx-ui-loader';
import { ngxUiLoaderConfig } from './app.ngx-ui-loader.config';

// @angular/material
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

registerLocaleData(es);

export function HttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient);
}

export function ApplicationInitializerFactory(translate: TranslateService, injector: Injector): any {
  return async () => {
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

    translate.addLangs(['es']);
    const existe = translate.getLangs().includes(translate.getBrowserLang());
    const idioma = existe ? translate.getBrowserLang() : 'es';

    translate.setDefaultLang(idioma);
    try {
      await translate.use(idioma).toPromise();
    }
    catch (err) {
      console.log(err);
    }

  };
}

@NgModule({
  declarations: [
    // Paginas
    AppComponent,
    EnConstruccionComponent,
    NotFoundComponent,
    ErrorComponent,
    SesionExpiradaComponent,

    // Componenentes
    PopupCookiesComponent,

    // Pipes
    BooleanToSiNoPipe
  ],
  imports: [
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderRouterModule,
    NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
    AppRoutingModule,
    ClickCursorModule,
    NgxSpinnerModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    TranslateModule,
    TooltipModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SimpleModalModule.forRoot(
      {
        container: 'modal-container'
      },
      {
        ...defaultSimpleModalOptions, ...{
          closeOnEscape: false,
          closeOnClickOutside: false
        }
      }
    ),
    HeaderModule,
    FooterModule
  ],
  entryComponents: [
    PopupCookiesComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-*' },
    { provide: MAT_DATE_LOCALE, useValue: 'es-*' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    {
      provide: APP_INITIALIZER,
      useFactory: ApplicationInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
    CookieService,
    CacheService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
