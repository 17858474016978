
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { EnConstruccionComponent } from './pages/en-construccion/en-construccion.component';
import { SesionExpiradaComponent } from './pages/sesion-expirada/sesion-expirada.component';
import { ErrorComponent } from './pages/error/error.component';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/inicio/inicio.module').then((m) => m.InicioModule)
  },

  // No aplicamos lazy loading
  { path: 'sesionexpirada', component: SesionExpiradaComponent },
  { path: 'enconstruccion', component: EnConstruccionComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'error/:message', component: ErrorComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
