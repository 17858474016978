import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-accesibilidad-modal',
  templateUrl: './accesibilidad-modal.component.html',
  styleUrls: ['./accesibilidad-modal.component.scss']
})
export class AccesibilidadModalComponent extends SimpleModalComponent<any, any> {
  constructor() { super(); }
}
