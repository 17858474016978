import * as $ from 'jquery';
import { Component, OnInit } from '@angular/core';
import { TimelineLite } from 'gsap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-en-construccion',
  templateUrl: './en-construccion.component.html',
  styleUrls: ['./en-construccion.component.scss']
})
export class EnConstruccionComponent implements OnInit {
  //#region atributos
  //#endregion

  //#region contructor
  constructor(private router: Router) { }
  //#endregion

  //#region Metodos Publicos (accesible html)
  public ngOnInit(): void {
    window.scroll(0, 0);
  
  }

  public inicio(): void {
    this.router.navigate(['/'], { replaceUrl: true });
  }
 
  //#endregion

}
