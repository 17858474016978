import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FooterComponent } from "./footer.component";
import { PoliticaPrivacidadModalComponent } from './politica-privacidad-modal/politica-privacidad-modal.component';
import { VisualizacionModalComponent } from './visualizacion-modal/visualizacion-modal.component';
import { AccesibilidadModalComponent } from './accesibilidad-modal/accesibilidad-modal.component';
import { PoliticaCookiesModalComponent } from './politica-cookies-modal/politica-cookies-modal.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
    declarations: [FooterComponent, 
        PoliticaPrivacidadModalComponent, 
        VisualizacionModalComponent, 
        AccesibilidadModalComponent,
        PoliticaCookiesModalComponent],
    imports: [
      CommonModule,
      TranslateModule.forChild({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
        isolate: false,
        extend: true
      })
    ],
    exports: [
        FooterComponent
    ],
    providers: [

    ]
  })
  export class FooterModule { }