
export enum PPCookiesEnum {
    btnGuardar = 1,
    btnAceptar = 2
}

export class OutputPPCookies{
    btnSelec: PPCookiesEnum;
    cookieAnalyticSel: boolean;
}


