import { Component } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { AccesibilidadModalComponent } from './accesibilidad-modal/accesibilidad-modal.component';
import { PoliticaPrivacidadModalComponent } from './politica-privacidad-modal/politica-privacidad-modal.component';
import { VisualizacionModalComponent } from './visualizacion-modal/visualizacion-modal.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  constructor(private simpleModalService: SimpleModalService) {}

  public OpenModal(parameter: string) {
    switch (parameter) {

      case "privacidad": {
        this.simpleModalService.addModal(PoliticaPrivacidadModalComponent, {});
        break;
      }
      case "visualizacion": {
        this.simpleModalService.addModal(VisualizacionModalComponent, {});
        break;
      }
      case "accesibilidad": {
        this.simpleModalService.addModal(AccesibilidadModalComponent, {});
      }
    }
  }
}
