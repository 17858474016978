import { Injectable } from '@angular/core';
import { KeyEnum } from '../resources/enums/keyEnum.enum';

@Injectable()
export class CacheService {
  public getKey(key: KeyEnum, session: boolean): Object {
    try {
      return JSON.parse(session ? sessionStorage.getItem(key) : localStorage.getItem(key));
    }
    catch (error) {
      console.error(error);
    }
  }

  public setKey(key: KeyEnum, obj: Object, session: boolean): void {
    try {
      this.limpiarClave(key, session);
      
      session ? sessionStorage.setItem(key, JSON.stringify(obj)) : localStorage.setItem(key, JSON.stringify(obj));
    }
    catch (error) {
      console.error(error);
    }
  }

  public limpiarClave(key: KeyEnum, session: boolean): void {
    try {
      session ? sessionStorage.removeItem(key) : localStorage.removeItem(key);
    }
    catch (error) {
      console.error(error);
    }
  }

  public limpiarCache(session: boolean, local: boolean): void {
    try {
      if (session)
        sessionStorage.clear();

      if (local)
        localStorage.clear();
    }
    catch (error) {
      console.error(error);
    }
  }

}
