import {
  NgxUiLoaderConfig,
  POSITION,
  SPINNER,
  PB_DIRECTION
} from 'ngx-ui-loader';

export const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsColor: '#1e88e5',
  fgsType: SPINNER.circle,
  fgsSize: 120,
  text: 'Cargando...',
  fgsPosition: POSITION.centerCenter,
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 5, // progress bar thickness
  pbColor: '#1e88e5',
  bgsPosition: POSITION.centerCenter,
  bgsOpacity: 0.5,
  bgsColor: '#00677f',
  bgsType: SPINNER.circle
};
