import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-visualizacion-modal',
  templateUrl: './visualizacion-modal.component.html',
  styleUrls: ['./visualizacion-modal.component.scss']
})
export class VisualizacionModalComponent extends SimpleModalComponent<any, any> {
  constructor() { super(); }
}
