import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-politica-cookies-modal',
  templateUrl: './politica-cookies-modal.component.html',
  styleUrls: ['./politica-cookies-modal.component.scss']
})
export class PoliticaCookiesModalComponent extends SimpleModalComponent<any, any> {
  constructor() { super(); }  
}
