import { Component } from '@angular/core';
import { faToggleOff, faToggleOn, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { OutputPPCookies, PPCookiesEnum } from '../../models/componentes/popup-cookies.model';

@Component({
  selector: 'app-popup-cookies',
  templateUrl: './popup-cookies.component.html',
  styleUrls: ['./popup-cookies.component.scss']
})
export class PopupCookiesComponent extends SimpleModalComponent<null, OutputPPCookies> {

  //#region atributos
  public toggleOff = faToggleOff;
  public toggleOn = faToggleOn;
  public modo: boolean;
  //#endregion

  //#region contructor
  constructor() {
    super();

    // Por defecto las analiticas de google estan activas
    this.modo = true;
  }
  //#endregion

  //#region Metodos Publicos (accesible html)

  public guardar(): void{
    const salida = new OutputPPCookies();
    salida.btnSelec = PPCookiesEnum.btnGuardar;
    salida.cookieAnalyticSel = this.modo;

    this.result = salida;
    this.close();
  }

  public aceptar(): void{
    const salida = new OutputPPCookies();
    salida.btnSelec = PPCookiesEnum.btnAceptar;

    this.result = salida;
    this.close();
  }

  public cambioToggle(): IconDefinition {
    return this.modo ? this.toggleOn : this.toggleOff;
  }

  public cambiarAnalytic(): void{
    this.modo = !this.modo;
  }
  //#endregion

}
